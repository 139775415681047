import React, { useEffect } from "react";
import Layout from "../components/layout/layout";
import { ImageBackground, MainDiv } from "../components/styledComponents";
import Image from "../components/image";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import "./contact.scss";
import useWindowSize from "../util/useWindowSize";

const Contact = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);

  const { width, height } = useWindowSize();

  const backgroundStyle = (() => {
    if (width >= 1366) {
      return {
        backgroundPosition: "center center, 100px 120px",
        backgroundPositionY: "",
        backgroundPositionX: "",
        backgroundSize: "100%, 35%",
      };
    }

    if (width >= 1024) {
      return {
        backgroundPosition: "center center, -60px 200px",
        backgroundSize: "100%, 40%",
      };
    }

    if (width >= 600) {
      return {
        backgroundPosition: "center center, -60px 380px",
        backgroundSize: "100%, 50%",
      };
    }

    if (width <= 600) {
      return {
        backgroundSize: "100%, 120%",
        backgroundPosition: "center center, -110px 200px",
      };
    }
  })();

  return (
    <Layout>
      <main
        css={css`
          transform: skew(0deg, -5deg);
          height: calc(100vh - 47px);
          margin-top: calc(-47px * 3);
        `}
      >
        <ImageBackground
          tag={"div"}
          fluid={[
            `linear-gradient(90deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.5) 100%)`,
            Image().contact,
          ]}
          style={{
            ...backgroundStyle,
            backgroundPositionY: "",
            backgroundPositionX: "",
          }}
          css={css`
            padding-top: calc(47px * 3);
            height: calc(100vh - 47px * 3);

            .content {
              transform: skew(0deg, 5deg);
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              font-family: "Brandon Grotesque", sans-serif;
              color: white;

              .title {
                font-size: 70px;
                text-align: center;
                max-width: 600px;
                line-height: 1;
              }
            }
          `}
          className={"contact-banner"}
        >
          <div className="content">
            <h1 className="title">Contact Us</h1>
          </div>
          <ImageBackground
            tag={"div"}
            fluid={Image().pattern2}
            css={css`
              height: 25px;
            `}
          />
        </ImageBackground>
      </main>
      <MainDiv
        css={css`
          margin-top: 70px;
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(300px, 600px));
          place-content: center;
          grid-column-gap: 30px;
          font-family: "Brandon Grotesque", sans-serif;

          p {
            font-size: 30px;
          }

          .row {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 15px;
          }

          .col {
            margin: 0 10px 10px;
            display: flex;
            flex-direction: column;
            flex: 1 100%;

            label {
              margin-bottom: 10px;
            }

            input,
            textarea {
              outline: none;
              border: 1px solid #ccc;
              padding: 0.5rem;
              font-size: 18px;
              border-radius: 4px;
            }
          }

          .submit-btn {
            outline: none;
            border: none;

            background-color: black;
            color: white;
            padding: 0.5rem 1rem;
            width: 200px;
            border-radius: 4px;
            cursor: pointer;

            :hover {
              background-color: lighten(#111, 10%);
            }
          }

          @media screen and (min-width: 768px) {
            margin-top: 110px;
            .row {
              flex-wrap: nowrap;
            }
          }
        `}
      >
        <div>
          <p>We love to hear from our guests!</p>
          <p>
            Please get in touch with your thoughts, suggestions, comments and
            questions.
          </p>
        </div>
        <div>
          <form name="contact" method="POST" data-netlify="true">
            <div className="row">
              <div className="col">
                <label htmlFor="first_name">First Name</label>
                <input type="text" name="first_name" />
              </div>
              <div className="col">
                <label htmlFor="last_name">Last Name</label>
                <input type="text" name="last_name" />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <label htmlFor="email">Email</label>
                <input type="text" name="email" />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <label htmlFor="subject">Subject</label>
                <input type="text" name="subject" />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <label htmlFor="message">Message</label>
                <textarea name="message" id="" cols="30" rows="10" />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <button type="submit" className="submit-btn">
                  Send
                </button>
              </div>
            </div>
          </form>
        </div>
      </MainDiv>
    </Layout>
  );
};

export default Contact;
